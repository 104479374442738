<template>
  <v-row>
    <v-col class="pb-0">
      <v-row class="pb-4">
        <!-- Left section of endpoint component -->
        <v-col cols=8>
          <v-row class="headline mb-2" align="center">
            {{ entry.summary }}
            <v-tooltip top transition="fade-transition" v-model="linkTooltip" color="rgba(0, 0, 0, 0.8)">
              <template v-slot:activator="{ on }">
                <v-btn @mouseenter="clipboardLinkHover()" icon @click="addLinktoClipboard(title + index)"><v-icon>link</v-icon></v-btn>
              </template>
              <span>{{ linkTooltipText }}</span>
            </v-tooltip>
          </v-row>

          <v-row class="subtitle-1 haswhitespace mb-1" align="center">
            <span class="font-weight-bold mr-2">PATH</span><kbd>{{ entry.path }}</kbd>
          </v-row>

          <v-row class="subtitle-1 haswhitespace mb-2">
            <span class="font-weight-bold mr-2">AUTHORIZATIONS</span>
            <span class="pa-0" v-for="(item, index) in entry.security" :key="index">{{ Object.keys(item)[0] }}<span v-if="index < (entry.security.length - 1)"> OR </span></span>
          </v-row>

          <v-row class="subtitle-1">Path Parameters</v-row>
          <v-row class="pa-3">
            <v-col>
              <v-row align="center" v-for="(param, index) in entry.parameters" :key="index">
                <v-col cols=2>
                  <v-row>{{ param.name }}</v-row>
                  <v-row v-if="param.required" class="overline red--text">required</v-row>
                </v-col>
                <v-col cols=8>
                  <v-text-field
                    :label="param.schema.type + ' <' + param.schema.format + '>'"
                    :placeholder="param.description"
                    hide-details
                    disabled
                    />
                </v-col>
              </v-row>
            </v-col>
          </v-row>

          <v-row class="subtitle-1 my-2">Responses</v-row>
          <v-expansion-panels accordion active-class="mb-2">
            <v-expansion-panel v-for="(response, index) in entry.responses" :key="index" :disabled="response.schema.properties == null">
              <v-expansion-panel-header class="grey--text text--darken-3 haswhitespace" :color="response.statusCode.startsWith('2') ? 'green accent-3' : 'red lighten-4'">
                <v-row>
                  <v-col class="flex-grow-0 font-weight-bold py-0 mr-2">{{ response.statusCode }}</v-col>
                  <v-col class="py-0">{{ response.description }}</v-col>
                  <v-col class="flex-grow-0 py-0" v-if="response.schema.properties != null">View Schema</v-col>
                </v-row>
              </v-expansion-panel-header>
              <v-expansion-panel-content v-if="response.schema.properties" class="pl-6 pt-3">
                <v-row class="title mb-1">Response Schema</v-row>
                <v-divider width="33%"/>
                <v-row v-for="(propertyVal, propertyName) in response.schema.properties" :key="propertyName" align="center" class="py-3">
                  <v-col cols=2 class="py-2">
                    <v-row>{{ propertyName }}</v-row>
                  </v-col>
                  <v-col cols=8 class="py-2">
                    <v-text-field
                      class="py-0"
                      :label="propertyVal.type + (propertyVal.format ? ' <' + propertyVal.format + '>': '')"
                      hide-details
                      disabled/>
                  </v-col>
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-col>
        <!-- Right section of endpoint component -->
        <v-col cols=4>
          <v-card class="blue-grey darken-4 white--text pa-4" height="100%">
            <v-row class="haswhitespace subtitle-1 mb-2 mx-0" align="center"><p class="red--text text-uppercase">{{ entry.method }}</p> {{ entry.path }}</v-row>
            <v-divider class="blue-grey lighten-4"/>
            <v-row class="title my-2 mx-0">Response Samples</v-row>
            <v-card class="black px-2" elevation=8>
              <v-tabs background-color="black" dark>
                <v-tab v-for="(response, index) in entry.responses" :key="index" :active-class="response.statusCode.startsWith('2') ? 'green--text text--accent-3' : 'red--text'">
                  {{ response.statusCode }}
                </v-tab>
                <v-tab-item class="black green--text text--accent-3 py-2" v-for="(response, index) in entry.responses" :key="index">
                  <!-- <code class="black green--text text--accent-3" style="white-space: pre;">{{ response.sampleSchema }}</code> -->
                  <vue-json-pretty
                    v-if="response.sampleSchema"
                    :deep="2"
                    :path="'res'"
                    :data="response.sampleSchema">
                  </vue-json-pretty>
                </v-tab-item>
              </v-tabs>
            </v-card>
          </v-card>
        </v-col>
      </v-row>
      <v-divider/>
    </v-col>
  </v-row>
</template>
<script>
import VueJsonPretty from 'vue-json-pretty'
import 'vue-json-pretty/lib/styles.css'

export default {
  components: {
    VueJsonPretty
  },
  props: {
    entry: Object,
    title: String,
    index: Number
  },
  data () {
    return {
      linkTooltip: false,
      linkTooltipText: 'Copy link to this section'
    }
  },
  methods: {
    clipboardLinkHover () {
      this.linkTooltip = true
      setTimeout(function () { this.linkTooltip = false }.bind(this), 2000)
    },
    addLinktoClipboard (path) {
      var link = window.location.origin + this.$route.path + '#' + path

      var textArea = document.createElement('textarea')
      // textArea.style.display = 'none'
      textArea.value = link

      document.body.appendChild(textArea)
      textArea.select()
      document.execCommand('copy')
      document.body.removeChild(textArea)

      console.log(link)
      this.linkTooltipText = 'Link copied to clipboard!'
      this.linkTooltip = true
      setTimeout(function () {
        this.linkTooltip = false
        this.linkTooltipText = 'Copy link to this section'
      }.bind(this), 2000)
    }
  }
}
</script>
