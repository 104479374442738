<template>
  <v-main class="py-0 px-2">
    <v-row>
      <!-- Side navigation -->
      <v-col cols=2 style="position: fixed;">
        <v-card>
          <v-navigation-drawer
          floating
          permanent
          width="100%"
          style="max-height: 90vh; overflow: scroll;">
            <v-list>
              <v-list-item class="py-2" @click="goTo('header', '')">{{ apiDocsStructure.meta.info.title }}</v-list-item>
              <v-list-group v-for="(entries, name) in apiDocsStructure.endpoints" :key="name" :ref="name + '_section'">
                <template v-slot:activator>
                  <v-list-item-title>{{ name }}</v-list-item-title>
                </template>
                <v-list-item-group v-model="sidepanelSections[name]" :ref="name + '_subsection'">
                  <v-list-item class="py-2" v-for="(entry, index) in entries" :key="index" @click="goTo(name, index)">{{ entry.summary }}</v-list-item>
                </v-list-item-group>
              </v-list-group>
            </v-list>
          </v-navigation-drawer>
        </v-card>
      </v-col>
      <!-- Main content -->
      <v-col cols=10 offset=2 style="overflow: scroll;">
        <v-row id="header">
          <v-col class="py-4">
            <v-row class="py-2 pb-2 text-h3">{{ apiDocsStructure.meta.info.title }} ({{ apiDocsStructure.meta.info.version }})</v-row>
            <v-row class="py-2 text-h6">{{ apiDocsStructure.meta.info.description }}</v-row>
            <v-row class="py-1"><a :href="apiDocsStructure.meta.externalDocs.url">{{ apiDocsStructure.meta.externalDocs.description }}</a></v-row>
            <v-row class="py-1 haswhitespace">Contact Support: <a :href="'mailto:' + apiDocsStructure.meta.info.contact.email">{{ apiDocsStructure.meta.info.contact.email }}</a></v-row>
            <v-row class="py-1"><a :href="apiDocsStructure.meta.info.termsOfService">Terms of Service</a></v-row>
            <v-row class="py-1"><a href="crxcavator_swagger.json" download="crxcavator_api.json">Download OpenAPI spec file</a></v-row>
          </v-col>
        </v-row>
        <v-divider/>
        <v-row v-for="(entries, title) in apiDocsStructure.endpoints" :key="title">
          <v-col>
            <v-row class="display-1 mt-4">{{ title }}</v-row>
            <EndpointEntry v-for="(entry, index) in entries" v-intersect.quiet="onIntersect" :key="index" :id="title + index" :entry="entry" :title="title" :index="index" />
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-main>
</template>
<script>
import EndpointEntry from '@/components/apidocscomponents/EndpointEntry'
const crxformatteddocs = require('@/assets/crx_apidocs_formatted.json')

export default {
  title: 'CRXcavator',
  components: {
    EndpointEntry
  },
  methods: {
    goTo (name, index) {
      this.autoMove = true
      setTimeout(() => { this.autoMove = false }, 1500)
      this.$vuetify.goTo(('#' + name + index), this.options)
    },
    onIntersect (entries, observer, isIntersecting) {
      if (isIntersecting && !this.autoMove) this.setActivePanel(entries[0].target.id)
    },
    setActivePanel (element) {
      this.activePanel = element
      var section = element.replace(/[0-9]/g, '')

      // Clicks open sidebar section
      if (!this.$refs[section + '_section'][0].isActive) {
        this.$refs[section + '_section'][0].click()
      }

      // Highlights list item in sidebar that corresponds to active element
      var listIndex = parseInt(element.slice(section.length))
      this.sidepanelSections[section] = listIndex

      this.$refs[section + '_subsection'][0].updateInternalValue(listIndex)
    }
  },
  data () {
    return {
      sampleValues: {
        string: 'string',
        integer: 0
      },
      // crxcavatorSwagger: crxcavatorSwagger,
      apiDocsStructure: crxformatteddocs,
      options: {
        easing: 'linear',
        duration: 10
      },
      sidepanelSections: {},
      activePanel: '',
      autoMove: false
    }
  },
  mounted () {
    console.log(this.$route)
    this.$nextTick(function () {
      if (this.$route.hash && this.$route.hash.length > 0) {
        this.$vuetify.goTo(this.$route.hash, this.options)
      }
    })
  }
}
</script>
<style>
html {
  scroll-behavior: smooth;
}
a {
  text-decoration: none;
}
.v-btn__content {
  width: 100%;
}
.v-expansion-panel-content__wrap {
  padding: 0;
}
.v-application code {
  font-size: inherit !important;
}
.v-application code:before {
  content: "" !important;
}
.vjs-tree .vjs-value__string {
  color: inherit;
}
</style>
